import React, { FC, ReactElement, SVGProps } from 'react';
import { IconBaseProps } from 'shared/types';
import { theme } from 'theme';

const CalendarIcon: FC<IconBaseProps & SVGProps<SVGSVGElement>> = ({
  width = 14,
  height = 16,
  fill = theme.palette.custom.darkCyan,
  ...restProps
}): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.5 2H9.5V1.32812C9.5 1.14062 9.5625 0.984375 9.6875 0.859375C9.82292 0.734375 9.98438 0.671875 10.1719 0.671875C10.3594 0.671875 10.5156 0.734375 10.6406 0.859375C10.7656 0.984375 10.8281 1.14062 10.8281 1.32812V2H12.1719C12.7344 2.01042 13.2031 2.20312 13.5781 2.57812C13.9531 2.95312 14.151 3.42708 14.1719 4V13.3438C14.151 13.9062 13.9531 14.375 13.5781 14.75C13.2031 15.125 12.7344 15.3177 12.1719 15.3281H2.82812C2.26562 15.3177 1.79688 15.125 1.42188 14.75C1.04688 14.375 0.848958 13.9062 0.828125 13.3438V4C0.848958 3.42708 1.04688 2.95312 1.42188 2.57812C1.79688 2.20312 2.26562 2.01042 2.82812 2H4.17188V1.32812C4.17188 1.21354 4.19792 1.10417 4.25 1C4.3125 0.895833 4.39583 0.817708 4.5 0.765625C4.60417 0.703125 4.71354 0.671875 4.82812 0.671875C4.95312 0.671875 5.06771 0.703125 5.17188 0.765625C5.27604 0.817708 5.35417 0.895833 5.40625 1C5.46875 1.10417 5.5 1.21354 5.5 1.32812V2ZM12.8281 7.32812H2.17188V13.3438C2.17188 13.5312 2.23438 13.6875 2.35938 13.8125C2.48438 13.9375 2.64062 14 2.82812 14H12.1719C12.3594 14 12.5156 13.9375 12.6406 13.8125C12.7656 13.6875 12.8281 13.5312 12.8281 13.3438V7.32812ZM5.5 3.32812V4C5.5 4.125 5.46875 4.23958 5.40625 4.34375C5.35417 4.4375 5.27604 4.51562 5.17188 4.57812C5.06771 4.64062 4.95312 4.67188 4.82812 4.67188C4.71354 4.67188 4.60417 4.64062 4.5 4.57812C4.39583 4.51562 4.3125 4.4375 4.25 4.34375C4.19792 4.23958 4.17188 4.125 4.17188 4V3.32812H2.82812C2.65104 3.33854 2.49479 3.40625 2.35938 3.53125C2.23438 3.65625 2.17188 3.8125 2.17188 4V6H12.8281V4C12.8281 3.8125 12.7604 3.65625 12.625 3.53125C12.5 3.40625 12.349 3.33854 12.1719 3.32812H10.8281V4C10.8281 4.1875 10.7656 4.34375 10.6406 4.46875C10.5156 4.59375 10.3594 4.65625 10.1719 4.65625C9.98438 4.65625 9.82292 4.59375 9.6875 4.46875C9.5625 4.34375 9.5 4.1875 9.5 4V3.32812H5.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default CalendarIcon;
