import React, { ReactElement, FC } from 'react';

import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { Language } from 'shared/types/language';
import { BaseProps } from 'shared/types';

import { ExpandableSettings } from 'components';

type LanguageSwitcherProps = {
  languages: Language[];
  selectedLanguage?: Language;
  onLanguageChange: (languageCode: string) => void;
} & BaseProps;

const useStyles = makeStyles(() => ({
  container: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    transform: `translateY(calc(-100% + ${48}px))`,
    transition: 'transform 0.3s',
    cursor: 'pointer',
    zIndex: 100,
  },
  expanded: {
    transform: `translateY(0px)`,
    boxShadow: theme.shadows[1],
  },
  selectedLanguage: {
    fontWeight: '700 !important' as unknown as number, // FIXME: Find a way to better solve the CSS specificity problem
    textTransform: 'uppercase',
    [theme.breakpoints.down(576)]: {
      display: 'none',
    },
  },
  item: {
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: theme.palette.custom.lightGrayishVioletAlt,
    },
  },
}));

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  languages,
  selectedLanguage,
  onLanguageChange,
  className,
}): ReactElement | null => {
  const classes = useStyles();

  const items = languages.filter(({ code }) => code !== selectedLanguage?.code);
  return (
    <ExpandableSettings
      className={className}
      selectedItemClassName={classes.selectedLanguage}
      selectedItem={selectedLanguage}
      itemClassName={classes.item}
      items={items}
      onItemSelected={onLanguageChange}
    />
  );
};

export default LanguageSwitcher;
