import { APP_NAME, IS_LOCALHOST, IS_PRODUCTION, IS_SANDBOX } from './appConstants';

// By convention we use the backend on the same domain as the UI.
// This preserves localhost as is.
function getApiUrl() {
  if (IS_LOCALHOST) return 'http://localhost:64665/';
  if (IS_PRODUCTION) return 'https://api.laasone.com/';
  if (IS_SANDBOX) return 'https://api.dev.laasone.com/';
  return window.location.origin.replace(`://${APP_NAME}.`, '://api.');
}

export const API_URL = getApiUrl();
