import { notFound } from 'routes';
import { useHistory } from 'react-router-dom';

export const useNotFound = (): (() => void) => {
  const history = useHistory();

  const navigateToNotFound = () => {
    history.replace(notFound);
  };

  return navigateToNotFound;
};
