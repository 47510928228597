export default class TextUtilities {
  public static truncate = function (str: string, length: number, ending: string): string {
    if (!str) {
      return '';
    }
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  public static nToBr = function (val: any) {
    return val.replace(/\n/g, '<br />');
  };

  public static nameLetters = function (val: string): string {
    if (!val) {
      return '';
    }
    const nameParts = val.split(' ');
    if (nameParts.length > 0) {
      let name = TextUtilities.truncate(nameParts[0], 1, '');
      if (nameParts.length > 1) {
        name = name + TextUtilities.truncate(nameParts[1], 1, '');
      }
      return name;
    }
    return '';
  };

  public static setDangerousHtml = function (val: any) {
    return { __html: val };
  };
}
