import { ICountry } from 'shared/constants/shipments/shipmentsCountries';
import { useFetchApi } from 'shared/functions/fetchApi';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import { ApiResponse } from '../types/api';
import { ClassifierItem, ClassifierItemRequest } from '../types/classifierItem';

type UseClassifiersReturnType = {
  fetchCountries: (request: ClassifierItemRequest) => Promise<ICountry[]>;
  fetchCharacterization: (request: ClassifierItemRequest) => Promise<ClassifierItem[]>;
};

export const useClassifiers = (): UseClassifiersReturnType => {
  const { fetchApi } = useFetchApi();

  const langIso = (language: string) => {
    const lang = getLanguageByCode(language);
    return lang?.code || 'en';
  };

  const fetchCountries = async ({
    language = 'en',
  }: ClassifierItemRequest): Promise<ICountry[]> => {
    const response = await fetchApi(
      `api/classifier/classifier-items/countries/${langIso(language)}`,
    );
    const { payload = [] }: ApiResponse<ClassifierItem[]> = await response.json();

    const countries = payload.map((item) => ({
      id: item.id,
      label: item.value,
      value: item.code,
      tCode: item.code,
    }));

    return countries;
  };

  const fetchCharacterization = async ({
    language = 'en',
  }: ClassifierItemRequest): Promise<ClassifierItem[]> => {
    const response = await fetchApi(
      `api/classifier/classifier-items/characterization/${langIso(language)}`,
    );
    const { payload = [] }: ApiResponse<ClassifierItem[]> = await response.json();

    return payload;
  };

  return {
    fetchCountries,
    fetchCharacterization,
  };
};
