const translations = {
    BACK: 'Tillbaka',
    ACCOUNT: 'Konto',
    WELCOME: 'Välkommen',
    OVERVIEW: 'Översikt',
    ORDERS: 'Beställningar',
    RFQ: 'Förfrågan',
    PROFILE: 'Profil',
    CONTACTS: 'Kontakter',
    INVOICES: 'Fakturor',
    MANAGE: 'Hantera',
    PAYMENT: 'Betalning',
    COMING_SHIPMENTS: 'Kommande leveranser',
    IN_PROGRESS_SHIPMENTS: 'Pågående leveranser',
    Delayed: 'Försenad',
    WAITING_RFQ: 'Väntar på förfrågan',
    YOU_ARE_LOGGED_OUT: "Du har loggats ut",
    OR: 'ELLER',
    CANCEL: 'Avbryt',
    CONTINUE: 'Fortsätt',
    LOGIN: 'Logga in',
    LOG_OUT: 'Logga ut',
    TOTAL: 'Totalt',
    LOAD_DETAILS: 'Laddningsdetaljer',
    FAILED_TO_LOG_IN: 'Inloggning misslyckades',
    FAILED_TO_LOG_IN_BLOCKED: 'Kontot är blockerat',
    CLOSE_ALERT: 'Stäng',
    ALL_SHIPMENTS_DONE: "För närvarande finns inga leveranser.",
    STATUS_CHANGED:'Status uppdaterad',
    DASHBOARD: {
        TITLE_1: 'Dagens / försenade upphämtningar och leveranser',
        TITLE_2: 'Kommande leveranser'
    },
    DATATABLE: {
        NO_RESULT: 'Inga poster hittades',
        SORT: 'Sortera',
        next: 'Nästa sida',
        previous: 'Föregående sida',
        rowsPerPage: 'Rader per sida',
        displayRows: 'av'
    },
    LOGIN_DATA: {
        TITLE: 'Inloggningsinformation',
        LAST_LOGIN: 'Senaste inloggning'
    },
    LOGIN_PAGE: {
        TITLE: "Partnerportal",
        TEXT: "Logga in på ditt konto",
        BUTTON: "Logga in",
        USERNAME: "Användarnamn",
        PASSWORD: "Lösenord",
        FORGOT_PASSWORD: "Glömt lösenord?",
        SIGN_UP: "Vårt system håller dig ansluten 24/7, säkerställer smidigt samarbete och hjälper oss att betjäna våra kunder effektivt.",
        SIGN_UPTEXT: "Tung transport har aldrig varit så här enkelt",
        AS_SHIPPER: "Registrera dig som avsändare!",
        AS_CARRIER: "Registrera dig som transportör!"
    },
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Language',
        CMR_ADDED: 'CMR ADDED',
        CMR_UPLOADED: 'CMR uploaded',
        COLLECTED: 'COLLECTED',
        DELIVERED: 'DELIVERED',
        DELIVERY_REFERENCE: 'Reference',
        DROP_OFF: 'Deliver',
        FROM: 'from',
        INSTRUCTIONS_LOADING: 'Instructions',
        INSTRUCTIONS_DELIVERY: 'Instructions',
        LOADING_REFERENCE: 'Reference',
        ORDER: 'Order',
        PHOTO_OF_SIGNED_CMR: 'Photo of the signed CMR',
        PICKUP: 'Collect',
        SEND_CMR: 'SEND CMR',
        TIME: 'Time',
        TIME_LC: 'time',
        TO: 'to'
    },
    PICKUP_AND_DELIVERY_DATES: 'Upphämtnings- och leveranstider',
    PICKUP_FROM: 'Upphämtning',
    DELIVERY_TO: 'Leverans',
    EARLIEST_PICKUP: 'Tidigare upphämtningsdatum',
    LATEST_PICKUP: 'Senaste upphämtningsdatum',
    EARLIEST_DELIVERY: 'Tidigare leveransdatum',
    LATEST_DELIVERY: 'Senaste leveransdatum',
    PricesOffered: 'Erbjudande skickat',
    WaitingPrices: 'Vänligen skicka ditt erbjudande',
    Rejected: 'Avvisad',
    Expired: 'Utgått',
    Approved: 'Godkänd',
    SPOT_REQUEST: {
        TITLE: 'Prisförfrågan',
        TITLE_DESC: 'Vänligen svara på vår prisförfrågan genom att ange dina priser',
        DETAILS: 'Detaljer',
        CARRIER: 'Partner',
        OPENED_UNTIL: 'Aktiv till',
        STATUS: 'Status',
        SHIPMENT: 'Sändningar',
        CONTACT_PERSON: 'Kontaktperson',
        CONTACT_PERSON_EMAIL: 'E-post',
        COMMENT: 'Kommentarer',
        YOUR_PRICES: 'Dina priser',
        PRICE: 'Pris',
        DELIVERY_TIME: 'Transportdagar',
        PRICE_COMMENT: 'Kommentar',
        PRICE_VALID_UNTIL: 'Pris giltigt till',
        ADD_PRICE: 'Lägg till pris',
        MAKE_OFFER: 'Skicka erbjudande',
        REJECT: 'Avvisa',
        ASTRICS_REQUIRED: '* obligatoriska fält',
        SPOT_REQUEST_MODAL: 'Avvisa att ange priser',
        REJECT_REASON: 'Anledning',
        SUBMIT_SUCCESS: 'Priser inskickade framgångsrikt. Tack!',
        ORDERER: "Köpare",
        LAASONE_COMMENT: "LaasOnes kommentar om erbjudna priser",
        NUMBER: "Nummer",
        DATE: "Datum",
        ASKED_FROM: "Förfrågans mottagare",
        PICKUP: "Upphämtningsadress",
        DELIVERY: "Leveransadress",
        OFFERED_PRICE: "Erbjudet pris",
        WAITING_PRICES_UNTIL: "Aktiv till"
    },
    ITEMS_IN_THE_SHIPMENT: 'Artiklar i transporten',
    SHIPMENT: {
        PICKUP_DELIVERY_CONTACTS: 'Kontaktinformation för upphämtning och leverans',
        PICKUP_DELIVERY_NOTES: 'Anteckningar',
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK:'Delivery with tail lift truck',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Pickup with tail lift truck',
        TRANSPORT_DANGEROUS_GOODS: 'Includes dangerous goods',
        ITEM: {
            SELECT_TYPE: 'Select type',
            DETAILS: {
                PC: 'pc',
                TYPE: 'Type',
                LENGTH: 'Length',
                LENGTH_TOOLTIP: 'The length is fixed for selected item type',
                WIDTH: 'Width',
                WIDTH_TOOLTIP: 'The width is fixed for selected item type',
                HEIGHT: 'Height',
                VOLUME: 'Volume',
                WEIGHT_PC: 'Weight/pc',
                TOTAL_WEIGHT: 'Total weight',
                LDM: 'LDM',
                LDM_TOOLTIP: 'Loading metres (running metres of trailer length in full width and height). This is automatically calculated based on length, width and quantity, but you can write a different value if needed.',
                REFERENCE_NUMBER: 'Reference number',
                PRODUCT_DESCRIPTION: 'Product description',
                UN_NO: 'UN no',
                UN_NO_TOOLTIP: 'Number, that identifies dangerous goods, hazardous substances and articles',
                UN: 'UN',
                UN_CODE: 'UN code',
                PG: 'PG',
                PG_TOOLTIP: 'Dangerous goods are assigned into 3 packing groups in accordance with the degree of danger they present',
                CLASS: 'Class',
                CLASS_TOOLTIP: 'Classification numbers for the dangerous goods',
                DANGEROUS_GOODS: 'Dangerous goods',
                COLD_FROZEN: 'Cold/frozen',
                STACKABLE: 'Stackable',
                DELICATE: 'Delicate',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Minimum temperature of the goods that they require for transport',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Maximum temperature of the goods that they require for transport',
                PROPER_SHIPPING_NAME: 'Proper shipping name',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Name to describe the hazard properties and the composition of dangerous goods',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Black powder, compressed or Gunpowder',
            },
            TYPE: {
                PALLET: 'Pallet',
                PACKAGE: 'Package',
                TRUCK: 'Truck',
                OTHER: 'Other',
                CONTAINER: 'Container',
                MACHINE:'Maskin'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR pallet',
                FIN_PALLET: 'FIN pallet',
                PALLET: 'Pallet',
                PACKAGE: 'Package',
                BOX: 'Box',
                FULL_TRUCK_LOAD: 'Full truck load',
                LONG_PALLET: 'Long pallet',
                HALF_PALLET: 'Half pallet',
                LDM: 'LDM',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Other',
                MACHINE:'Maskin'
            },
            CLASS: {
                EXPLOSIVES: 'Explosives',
                GASES: 'Gases',
                FLAMMABLE_LIQUIDS: 'Flammable Liquids',
                FLAMMABLE_SOLIDS: 'Flammable Solids',
                OXIDIZERS: 'Oxidizers',
                TOXIC_SUBSTANCES: 'Toxic Substances',
                RADIOACTIVE: 'Radioactive',
                CORROSIVES: 'Corrosives',
                MISCELLANOUS: 'Miscellanous',
            },
            PG: {
                PG1: 'Packing Group I: high danger',
                PG2: 'Packing Group II: medium danger',
                PG3: 'Packing Group III: low danger',
            },
        },
    },
    SWITCH: {
        YES: 'YES',
        NO: 'NO',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'This username already exists',
    'This company already exists': 'This company already exists',
    ORDER: {
        NUMBER: "Nummer",
        CONSIGNOR: "Avsändare",
        PICKUP: "Upphämtningsadress",
        PICKUP_DATE: "Upphämtningsdatum",
        CONSIGNEE: "Mottagare",
        DELIVERY: "Leveransadress",
        DELIVERY_DATE: "Leveransdatum",
        ORDERER: "Köpare",
        STATUS: "Status",
        STATUSES: {
            NEW: 'Ny',
            IN_PROGRESS: 'Pågående',
            IN_TRANSIT: 'Upphämtad',
            DELIVERED: 'Levererad',
            CANCELED: 'Avbruten',
            PICKED_UP: 'Upphämtad'
        },
        CHANGE_STATUS: 'Ändra status',
        CHANGE_STATUS_TEXT: 'Är du säker på att du vill ändra status?'
    },
    INVOICE: {
        TITLE: 'Endast självfakturerade fakturor visas i listan',
        NUMBER: 'Nummer',
        DATE: 'Datum',
        Total_SUM: 'Totala summan'
    },
    PROFILE_PAGE: {
        INFO: 'Om du märker några fel i din profil, vänligen meddela oss på info@laasone.com.',
        TITLE_ABOUT: 'Företagsinformation',
        TITLE_ADDRESS: 'Adressinformation',
        TITLE_SELF_BILLING: 'Självfakturering information',
        COMPANY_NAME: 'Företagsnamn',
        REGISTER_NUMBER: 'Registreringsnummer',
        VAT_NUMBER: 'Momsnummer',
        EMAIL: 'E-post',
        DEFAULT_LANGUAGE: 'Standardspråk',
        ADDRESS: 'Adress',
        POSTAL_CODE: 'Postnummer',
        CITY: 'Stad',
        STATE: 'Län',
        COUNTRY: 'Land',
        SELF_BILLING_ACTIVATED: 'Aktiverad',
        PAYMENT_TERMS_DAYS: 'Betalningsvillkor för inköp i dagar',
        BILLING_FREQUENCY: 'Faktureringsfrekvens',
        INVOICE_EMAIL: 'Faktura skickas till e-post'
    },
    NOT_FOUND_HEADING: "Åh nej!",
    NOT_FOUND_DESC: "Sidan du letar efter finns inte eller ",
    NOT_FOUND_DESC_2: "du har inte behörighet att se den.",
    NOT_FOUND_ERROR_CODE: "Felmeddelande: 404",
    GO_TO_HOMEPAGE: "Gå till startsidan",
    LIST: 'Lista',
    ALERT:{
        ORDER_LIST: 'Dina beställningar från de senaste 30 dagarna',
        RFQ_LIST: 'Dina offertförfrågningar från de senaste 30 dagarna'
    }
}
export default translations;