import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
import { AppHeaderDropdown } from './Header/index';
import { useUser } from 'shared/hooks/useUser';
import { getLanguageByCode } from '../shared/functions/getLanguageByCode';
import { languages } from '../shared/constants/languages';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state: any) => state.sidebarShow);
  const user = useUser();
  const { i18n, t } = useTranslation();

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <div className="d-flex">
          <CHeaderToggler
            className="ps-1"
            onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          >
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
          <p className="h5 mb-0 m-l-5 fst-italic fw-bold text-opacity-25">
            {t('WELCOME')}, {user?.profile?.company}
          </p>
        </div>
        <div className="header-right">
          <div className="language-switcher">
            <LanguageSwitcher
              onLanguageChange={onLanguageChange}
              selectedLanguage={getLanguageByCode(i18n.language)}
              languages={languages}
              className={''}
            />
          </div>
          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav>
        </div>
      </CContainer>
      {/*<CHeaderDivider />*/}
    </CHeader>
  );
};

export default AppHeader;
