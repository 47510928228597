import React, { FC, ReactElement, useRef, useEffect } from 'react';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import Alert, { AlertProps as MaterialAlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from 'shared/styles/common';
import { theme } from 'theme';
import { Typography } from 'components';

export type AlertTypes = 'default' | 'error' | 'info' | 'success';

type StyleProps = {
  isSmall: boolean;
};

const useStyles = makeStyles({
  root: ({ isSmall }: StyleProps) => ({
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: '12px',
    '& .MuiAlert-message': {
      fontSize: '16px',
      lineHeight: '22px',
      paddingTop: '13px',
      paddingBottom: '13px',
      display: isSmall ? 'block' : 'flex',
    },
  }),
  colorInvert: {
    color: theme.palette.custom.white,
  },
  fontInvert: {
    fontWeight: 'normal',
  },
  alertFont: {
    fontWeight: 'bold',
  },
  closeButton: {
    marginLeft: '15px',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  closeButtonLineBreak: {
    display: 'block',
    padding: '8px 0px 0px 0px',
    marginLeft: '0',
  },
  info: {
    backgroundColor: theme.palette.custom.alertInfo,
  },
  success: {
    backgroundColor: theme.palette.custom.alertBackSuccess,
  },
  error: {
    backgroundColor: theme.palette.custom.alertError,
  },
  fullWidth: {
    position: 'relative',
    top: '0',
    width: '100%',
    borderRadius: 'inherit',
    boxShadow: theme.shadows[1],
    padding: '8px 80px',
    zIndex: 200,
  },
  nonFullWidth: {
    borderRadius: '12px',
    padding: '8px 40px',
  },
  lessPadding: {
    padding: '8px 24px',
  },
  borderRadius: {
    borderRadius: '12px',
  },
});

type AlertProps = {
  children: React.ReactNode;
  color?: AlertTypes;
  fullWidth?: boolean;
  lessPadding?: boolean;
  onClose: () => void;
  borderRadius?: boolean;
  hideCloseButton?: boolean;
} & Omit<MaterialAlertProps, 'color'>;

const SystemAlert: FC<AlertProps> = ({
  children,
  color = 'info',
  onClose,
  fullWidth,
  lessPadding,
  borderRadius,
  hideCloseButton,
  ...props
}): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [alertWidth, setAlertWidth] = React.useState(0);
  const commonClasses = useCommonStyles();
  useEffect(() => {
    if (ref?.current) {
      setAlertWidth(ref.current.offsetWidth);
    } else {
      setAlertWidth(0);
    }
  }, []);

  const isSmall = alertWidth > 0 && alertWidth < 900;

  const classes = useStyles({ isSmall });
  const { t } = useTranslation();
  const [open] = React.useState(true);

  const colorDefault = color === 'info';
  const colorError = color === 'error';
  const colorSuccess = color === 'success';
  const fullWidthView = fullWidth === undefined ? true : fullWidth;
  const lessPaddingView = lessPadding === undefined ? false : lessPadding;

  return (
    <div className={clsx(classes.root)} ref={ref}>
      <Collapse in={open}>
        <Alert
          icon={false}
          color={color === 'default' ? 'info' : color}
          className={clsx(
            classes.alertFont,
            classes.colorInvert,
            colorDefault && classes.info,
            colorError && classes.error,
            colorSuccess && classes.success,
            fullWidthView ? classes.fullWidth : classes.nonFullWidth,
            lessPaddingView && classes.lessPadding,
            borderRadius && classes.borderRadius,
          )}
          {...props}
        >
          {children}
          <Typography
            className={clsx(
              classes.fontInvert,
              classes.colorInvert,
              classes.closeButton,
              isSmall && classes.closeButtonLineBreak,
              hideCloseButton && commonClasses.hidden,
            )}
            link
            onClick={() => {
              onClose();
            }}
          >
            {t('CLOSE_ALERT')}
          </Typography>
        </Alert>
      </Collapse>
    </div>
  );
};

export default SystemAlert;
