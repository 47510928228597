import { APP_NAME, IS_DEV } from 'shared/constants/appConstants';

// By convention we use the backend on the same domain as the UI.
// E.g. 'booking.audit.speyssandbox.com' uses backend '4plapi.audit.speyssandbox.com'
// This preserves localhost as is.
// Production partner has exception until backend would also move to speys.com - then convention can be preserved.
function getBackendHost(): string {
  if (IS_DEV) return 'http://localhost:64665/';
  else if ('https://partner.speys.com' === window.location.origin)
    return 'https://4plapi.speysdpl.com/';
  else return window.location.origin.replace(`://${APP_NAME}.`, '://4plapi.') + '/';
}

export const BACKEND_HOST = getBackendHost();

export const BACKEND_HOST_AUTH = (): string => {
  return BACKEND_HOST;
};
export const BACKEND_HOST_MAIN = (): string => {
  return BACKEND_HOST;
};
export const BACKEND_HOST_TRANSLATIONS = (): string => {
  // for developer's convenience, use translations from sandbox normally.
  if (IS_DEV) return 'https://4plapi.dev.speyssandbox.com/';
  else return BACKEND_HOST;
};
export const BACKEND_HOST_FILE_UPLOAD = (): string => {
  return BACKEND_HOST;
};
export const BACKEND_HOST_TRACKING = '';

/*
    Backend routes
 */
export const endpoints = {
  auth: {
    register: 'api/partner-portal/partner/register',
    login: 'api/partner-portal/authorize/connect/token',
  },
};

/*
    Frontend routes
 */
export const routePaths = {
  dashboard: '/dashboard',
  dashboard1: '/',
  drivers: {
    list: '/partner-portal/driver',
    new: '/partner-portal/driver/new',
    edit: '/partner-portal/driver/:id',
    editId: (id: number): string => `/partner-portal/driver/${id}`,
  },
  orders: {
    list: '/orders',
    edit: '/orders/:id',
    editId: (id: number): string => `/orders/${id}`,
  },
  rfq: {
    list: '/rfq',
    edit: '/rfq/:viewGuid',
    editId: (id: string): string => `/rfq/${id}`,
  },
  shipments: {
    list: '/shipments',
    new: '/shipments/new',
    edit: '/shipments/:id',
    tracking: '/shipments/:id/tracking',
    drag: '/shipments/DragnDrop',
    editId: (id: number | string): string => `/shipments/${id}`,
    trackingUrl: (id: number | undefined): string => `/shipments/${id}/tracking`,
  },
  request: {
    list: '/partner-portal/request',
    edit: '/partner-portal/request/:id',
    editId: (id: number): string => `/partner-portal/request/${id}`,
  },
  companies: {
    myCompany: '/company',
  },
  addressBook: {
    list: '/address_book',
    new: '/address_book/new',
    edit: '/address_book/:id',
    editId: (id: number): string => `/address_book/${id}`,
  },
  vehicles: {
    list: '/partner-portal/vehicle',
    new: '/partner-portal/vehicle/new',
    edit: '/partner-portal/vehicle/:id',
    editId: (id: number): string => `/partner-portal/vehicle/${id}`,
  },
  sessions: {
    error: '/sessions/error',
  },
  settings: '/settings',
  login: '/login/:code?',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  profile: '/profile',
  driverView: '/d/:driverViewGuid',
};

export const errorPageIgnoreList = [routePaths.driverView];

export const navigateToPaths = {
  login: '/login',
};
