import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box, { BoxProps } from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { BaseProps } from 'shared/types';
import { generateDate } from 'shared/functions/date';

import { theme } from 'theme';

import { Flag, Typography } from 'components';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useCommonStyles } from 'shared/styles/common';
import PickupIcon from '../Icons/PickupIcon/PickupIcon';
import DeliveryIcon from '../Icons/DeliveryIcon/DeliveryIcon';
import QuestionMarkIcon from '../Icons/QuestionMarkIcon/QuestionMarkIcon';

type ShipmentDateProps = {
  fixedDate?: boolean;
  companyName?: string;
  address?: string;
  type: 'pickup' | 'delivery';
  earliestDate?: Date | null;
  confirmedEarliestDate?: Date | null;
  latestDate?: Date | null;
  confirmedLatestDate?: Date | null;
  boxProps?: BoxProps;
  country: string;
} & BaseProps;

const useStyles = makeStyles({
  dateLabel: {
    color: theme.palette.custom.veryDarkGrayAlt2,
  },
  dateWithWarning: {
    color: theme.palette.custom.strongViolet,
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    left: 5,
  },
  dateIcon: {
    '& svg': {
      minWidth: 42,
    },
  },
  flagStyle: {
    position: 'absolute',
    top: 0,
    right: 4,
  },
});

const ShipmentDate: FC<ShipmentDateProps> = ({
  type,
  fixedDate,
  earliestDate,
  confirmedEarliestDate,
  latestDate,
  confirmedLatestDate,
  companyName,
  address,
  className,
  boxProps = {},
  country,
}): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  let isEarliestDateChanged = confirmedEarliestDate?.getTime() !== earliestDate?.getTime();
  let isLatestDateChanged = confirmedLatestDate?.getTime() !== latestDate?.getTime();

  // do not change date styles and don't show tooltips when all confirmed dates are null
  if (!confirmedEarliestDate && !confirmedLatestDate) {
    isEarliestDateChanged = false;
    isLatestDateChanged = false;
  }

  const earliestDateFormatted = earliestDate ? generateDate(earliestDate, 'dd.MM.yyyy HH:mm') : '–';
  const latestDateFormatted = latestDate ? generateDate(latestDate, 'dd.MM.yyyy HH:mm') : '–';
  const confirmedEarliestDateFormatted = confirmedEarliestDate
    ? generateDate(confirmedEarliestDate, 'dd.MM.yyyy HH:mm')
    : '–';
  const confirmedLatestDateFormatted = confirmedLatestDate
    ? generateDate(confirmedLatestDate, 'dd.MM.yyyy HH:mm')
    : '–';

  const earliestDateTooltip = t('SHIPMENT.CUSTOMER_CONFIRMED').replace(
    '{0}',
    confirmedEarliestDateFormatted,
  );
  const latestDateTooltip = t('SHIPMENT.CUSTOMER_CONFIRMED').replace(
    '{0}',
    confirmedLatestDateFormatted,
  );

  const renderDateWithWarning = (date: string, tooltip: string) => (
    <Tooltip title={tooltip}>
      <span className={classes.dateWithWarning}>
        {date}
        <QuestionMarkIcon className={classes.warningIcon} />
      </span>
    </Tooltip>
  );
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      display="flex"
      alignItems="center"
      border={1}
      borderRadius={16}
      borderColor={theme.palette.custom.lightGray}
      paddingX={7.5}
      paddingY={8}
      className={clsx(classes.dateIcon, className)}
      {...boxProps}
    >
      {type === 'pickup' ? <PickupIcon /> : <DeliveryIcon />}
      <Box marginLeft={13} position="relative">
        <Box className={classes.flagStyle}>
          <Flag flag={country} />
        </Box>
        <Typography variant="h6">
          {t(type === 'pickup' ? 'PICKUP_FROM' : 'DELIVERY_TO')}{' '}
          <span className={commonClasses.mt1} style={{ display: 'block' }}>
            {companyName || address}
          </span>
        </Typography>
        <Box marginTop={3} display={!isDownMd ? 'flex' : 'block'}>
          <Box marginRight={8}>
            <Typography variant="body2" className={classes.dateLabel}>
              {!fixedDate && t(type === 'pickup' ? 'EARLIEST_PICKUP' : 'EARLIEST_DELIVERY')}
              {fixedDate && t(type === 'pickup' ? 'FIXED_PICKUP' : 'FIXED_DELIVERY')}
            </Typography>
            <Typography variant="body2" fontWeight="semibold">
              {isEarliestDateChanged
                ? renderDateWithWarning(earliestDateFormatted, earliestDateTooltip)
                : earliestDateFormatted}
            </Typography>
          </Box>
          {!fixedDate && (
            <div className={isDownMd ? commonClasses.mt2 : ''}>
              <Typography variant="body2" className={classes.dateLabel}>
                {t(type === 'pickup' ? 'LATEST_PICKUP' : 'LATEST_DELIVERY')}
              </Typography>
              <Typography variant="body2" fontWeight="semibold">
                {isLatestDateChanged
                  ? renderDateWithWarning(latestDateFormatted, latestDateTooltip)
                  : latestDateFormatted}
              </Typography>
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShipmentDate;
