import React, { ReactElement, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import isNil from 'lodash/isNil';

import { theme } from 'theme';

import { BaseProps } from 'shared/types';
import { ShipmentDetailsRow } from 'shared/types/shipment/shipments';

import { Accordion, AccordionSummary, PalletIcon, Typography, DataCell } from 'components';
import DropdownIcon2 from 'components/Icons/DropdownIcon/DropdownIcon2';
import {
  shipmentItemSubTypeLabels,
  shipmentItemTypeLabels,
} from 'shared/constants/shipments/shipmentItemTypeLabels';
import { shipmentItemSubTypesToTypes } from 'shared/functions/shipments/shipmentItemTypesMapping';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { useCommonStyles } from 'shared/styles/common';

type ShipmentItemProps = {
  data: ShipmentDetailsRow;
  noAccordion?: boolean;
} & BaseProps;

const useStyles = makeStyles({
  paletteIcon: {
    marginRight: theme.spacing(3),
  },
  cellLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.custom.veryDarkGrayAlt2,
    display: 'flex',
    alignItems: 'center',
  },
  cellText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
  coldFrozenHyphen: {
    color: theme.palette.custom.gray,
    margin: theme.spacing(0, 2),
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
});

const ShipmentItem: FC<ShipmentItemProps> = ({ data, noAccordion }): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded(!expanded);

  const {
    typeId,
    length,
    width,
    height,
    volume,
    weight,
    totalWeight,
    ldm,
    productDescription,
    stackable,
    delicateGoods,
    quantityId,
    coldFrozen,
    temperature,
    temperatureMax,
    dangerousGoods,
    dangerousGoodsInfo,
  } = data;

  const isStackable = t(stackable ? 'SWITCH.YES' : 'SWITCH.NO');
  const isDelicate = t(delicateGoods ? 'SWITCH.YES' : 'SWITCH.NO');
  const dangerousGoodsData =
    dangerousGoodsInfo && dangerousGoodsInfo.length ? dangerousGoodsInfo[0] : null;
  const coldFrozenCell = (
    <>
      {coldFrozen ? (
        <>
          {temperature} °C{' '}
          {temperatureMax && (
            <>
              <span className={classes.coldFrozenHyphen}>–</span>
              {temperatureMax} °C
            </>
          )}
        </>
      ) : (
        t('SWITCH.NO')
      )}
    </>
  );

  const verticalLine = (
    <Box width="1px" height={38} bgcolor={theme.palette.custom.lightGray} mr={6} />
  );

  const transitionProps = { timeout: 200 };
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Accordion
      expanded={noAccordion || expanded}
      onChange={noAccordion ? undefined : toggleExpanded}
      TransitionProps={transitionProps}
    >
      <AccordionSummary
        expandIcon={
          noAccordion || !isMdUp ? null : <DropdownIcon2 variant="dark" width={15} height={14} />
        }
        noAccordion={noAccordion}
      >
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          width={1}
          className="shipment-item-AccordionSummary"
        >
          <Box display="flex" className={clsx(!isMdUp && commonClasses.mt3)}>
            <PalletIcon className={classes.paletteIcon} />
            <Box width={108} className={classes.cellWrapper}>
              <Typography component="p" variant="body2" fontWeight="bold">
                {!isNil(typeId)
                  ? t(shipmentItemTypeLabels[shipmentItemSubTypesToTypes[typeId]])
                  : '-'}
              </Typography>
              <Typography component="p" variant="caption">
                {!isNil(typeId) ? t(shipmentItemSubTypeLabels[typeId]) : '-'}
              </Typography>
            </Box>
            <Box width={78} className={classes.cellWrapper}>
              <Typography component="p" variant="body2" fontWeight="bold">
                {quantityId} {t('SHIPMENT.ITEM.DETAILS.PC')}
              </Typography>
            </Box>
          </Box>
          {!isMdUp && <Box className={clsx(classes.break, commonClasses.mt3)} />}
          <Box display="flex">
            <DataCell
              width={79}
              label={t('SHIPMENT.ITEM.DETAILS.LENGTH')}
              data={length}
              unit="cm"
            />
            <DataCell width={78} label={t('SHIPMENT.ITEM.DETAILS.WIDTH')} data={width} unit="cm" />
            <DataCell
              width={79}
              label={t('SHIPMENT.ITEM.DETAILS.HEIGHT')}
              data={height}
              unit="cm"
            />
            <DataCell
              width={79}
              label={t('SHIPMENT.ITEM.DETAILS.VOLUME')}
              data={volume}
              unit="m3"
            />
            {isMdUp && verticalLine}
          </Box>
          {!isMdUp && <Box className={clsx(classes.break, commonClasses.mt3)} />}
          <Box display="flex">
            <DataCell
              width={87}
              label={t('SHIPMENT.ITEM.DETAILS.WEIGHT_PC')}
              data={weight}
              unit="kg"
            />
            <DataCell
              width={99}
              label={t('SHIPMENT.ITEM.DETAILS.TOTAL_WEIGHT')}
              data={totalWeight}
              unit="kg"
            />
            {verticalLine}
            <DataCell width={50} label={t('SHIPMENT.ITEM.DETAILS.LDM')} data={ldm} />
          </Box>
          {!isMdUp && <Box className={clsx(classes.break, commonClasses.mt3)} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails className="test">
        <Box pl={62.5} pb={4.25} className="shipment-item-extra">
          <Box display="flex" mb={6}>
            <DataCell
              width={!isMdUp ? undefined : 443}
              label={t('SHIPMENT.ITEM.DETAILS.PRODUCT_DESCRIPTION')}
              data={productDescription}
            />
          </Box>
          <Box display="flex" mb={6}>
            <DataCell
              width={190}
              label={t('SHIPMENT.ITEM.DETAILS.COLD_FROZEN')}
              data={coldFrozenCell}
            />
            <DataCell width={124} label={t('SHIPMENT.ITEM.DETAILS.STACKABLE')} data={isStackable} />
            <DataCell width={100} label={t('SHIPMENT.ITEM.DETAILS.DELICATE')} data={isDelicate} />
            {dangerousGoods && (
              <>
                <DataCell
                  width={236}
                  label={t('SHIPMENT.ITEM.DETAILS.DANGEROUS_GOODS')}
                  data={t('SWITCH.YES').toString()}
                />
              </>
            )}
          </Box>
          {dangerousGoods && (
            <Box display="flex" mb={6}>
              <DataCell
                width={90}
                label={t('SHIPMENT.ITEM.DETAILS.UN_CODE')}
                data={dangerousGoodsData?.un}
              />
              <DataCell
                width={100}
                label={t('SHIPMENT.ITEM.DETAILS.PG')}
                data={dangerousGoodsData?.pg}
              />
              <DataCell
                width={126}
                label={t('SHIPMENT.ITEM.DETAILS.CLASS')}
                data={dangerousGoodsData?.classVal}
              />
              <DataCell
                maxWidth={205}
                label={t('SHIPMENT.ITEM.DETAILS.PROPER_SHIPPING_NAME')}
                data={dangerousGoodsData?.properShippingName}
              />
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default ShipmentItem;
