import React from 'react';
import { CFooter } from '@coreui/react';

const AppFooter = () => {
  return (
    <CFooter>
      <div>
        <a href="https://laasone.com" target="_blank" rel="noopener noreferrer">
          LaasOne
        </a>
        <span className="ms-1">Logistics As a Service</span>
      </div>
      <div className="ms-auto"></div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
