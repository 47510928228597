import React, { FC, lazy, ReactElement } from 'react';
import {
  match as RouteMatch,
  RouteComponentProps,
  RouteProps as NativeRouteProps,
} from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { NotFound } from './scenes';
import UnauthenticatedApp from './app/UnauthenticatedApp';
import { routePaths } from './shared/constants/api.constants';

const DriverApp = lazy(() => import('./scenes/DriverView/DriverApp'));
const SpotRequestApp = lazy(() => import('./scenes/SpotRequest/SpotRequestApp'));
const Dashboard = lazy(() => import('./scenes/Dashboard/Dashboard'));
const Contacts = lazy(() => import('./scenes/Contacts/Contacts'));
const Rfqs = lazy(() => import('./scenes/SpotRequests/SpotRequests'));
const Profile = lazy(() => import('./scenes/Profile/Profile'));
const Orders = lazy(() => import('./scenes/Orders/Orders'));
const Order = lazy(() => import('./scenes/Order/Order'));
const Invoices = lazy(() => import('./scenes/Invoices/Invoices'));
const RfqView = lazy(() => import('./scenes/SpotRequest/SpotRequestView'));

export type RouteLocationProps = {
  pathname: string;
  search: string;
};

export type RouteProps = NativeRouteProps &
  BreadcrumbsRoute & {
    name: string;
    path: string;
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
    exact: boolean;
    environment?: string;
  };

type AvailableRouteParams = {
  shipmentId: string;
  priceRequestId: string;
};

type BreadcrumbProps = {
  match: RouteMatch<AvailableRouteParams>;
  location: RouteLocationProps;
};
export const routeLogin = '/';
export const routeDriverView = '/d/:driverViewGuid';
export const routeSpotRequestView = '/spot_request/:viewGuid';
export const notFound = '/404';
export const routeDashboard = '/dashboard';
export const routeContacts = '/contacts';
export const routeRfqs = '/rfq';
export const routeProfile = '/profile';
export const routeOrders = '/orders';
export const routeInvoices = '/invoices';
const locationMapping: Record<string, string> = {
  '/': 'BREADCRUMB.SHIPMENTS',
  '/shipments': 'BREADCRUMB.SHIPMENTS',
  '/shipments/new-shipment': 'BREADCRUMB.SHIPMENTS_ADD',
  '/price-requests': 'BREADCRUMB.PRICE_REQUESTS',
  '/price-requests/new-price-request': 'BREADCRUMB.PRICE_REQUESTS_ADD',
  '/company': 'BREADCRUMB.COMPANY_PROFILE',
  '/templates': 'BREADCRUMB.TEMPLATES',
  '/templates/new-template': 'BREADCRUMB.TEMPLATES_ADD',
};

export const LocalizedBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const label = locationMapping[match.path];
  const { t } = useTranslation();
  return <span>{label && t(label)}</span>;
};

export const EditShipmentBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const { t } = useTranslation();
  return (
    <span>
      {t('BREADCRUMB.EDIT_SHIPMENT_DRAFT_NO').replace('{0}', `S-${match.params.shipmentId}`)}
    </span>
  );
};

export const EditTemplateBreadcrumb: FC<BreadcrumbProps> = (): ReactElement => {
  const { t } = useTranslation();
  return <span>{t('BREADCRUMB.EDIT_TEMPLATE')}</span>;
};

export const EditPriceRequestBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  const { t } = useTranslation();

  return (
    <span>
      {t('BREADCRUMB.EDIT_PRICE_REQUEST_DRAFT_NO').replace(
        '{0}',
        `PR-${match.params.priceRequestId}`,
      )}
    </span>
  );
};

export const DynamicBreadcrumb: FC<BreadcrumbProps> = ({ match }): ReactElement => {
  return <span />;
};

const routes: RouteProps[] = [
  {
    name: 'notFound',
    path: '/404',
    exact: true,
    component: NotFound,
  },
  {
    name: 'dashboard',
    path: `${routeDashboard}`,
    exact: true,
    component: Dashboard,
  },
  {
    name: 'contacts',
    path: `${routeContacts}`,
    exact: true,
    component: Contacts,
  },
  {
    name: 'rfq',
    path: `${routeRfqs}`,
    exact: true,
    component: Rfqs,
  },
  {
    name: 'rfq',
    path: `${routePaths.rfq.edit}`,
    exact: true,
    component: RfqView,
  },
  {
    name: 'orders',
    path: `${routeOrders}`,
    exact: true,
    component: Orders,
  },
  {
    name: 'order',
    path: `${routePaths.orders.edit}`,
    exact: true,
    component: Order,
  },
  {
    name: 'invoices',
    path: `${routeInvoices}`,
    exact: true,
    component: Invoices,
  },
  {
    name: 'profile',
    path: `${routeProfile}`,
    exact: true,
    component: Profile,
  },
];

const routesPublic: RouteProps[] = [
  {
    name: 'login',
    path: `${routeLogin}`,
    exact: true,
    component: UnauthenticatedApp,
    breadcrumb: LocalizedBreadcrumb,
  },
  {
    name: 'driverView',
    path: `${routeDriverView}`,
    exact: true,
    component: DriverApp,
    breadcrumb: LocalizedBreadcrumb,
  },
  {
    name: 'spotRequestView',
    path: `${routeSpotRequestView}`,
    exact: true,
    component: SpotRequestApp,
    breadcrumb: LocalizedBreadcrumb,
  },
  {
    name: 'notFound',
    path: '/404',
    exact: true,
    component: NotFound,
  },
];
export { routes, routesPublic };
