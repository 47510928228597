import { toFormData } from 'shared/functions/toFormData';
import { callFetchApi, useFetchApi } from 'shared/functions/fetchApi';
import {
  InvitationProps,
  InvitationResponseProps,
  LogInProps,
  LogInResponseType,
  RefreshTokenProps,
} from 'shared/types/session';
import { ApiResponse } from 'types/api';

type UseSessionApiReturnType = {
  logIn: (request: LogInProps) => Promise<LogInResponseType>;
  refreshUserToken: (request: RefreshTokenProps) => Promise<LogInResponseType>;
  findInvitation: (request: InvitationProps) => Promise<InvitationResponseProps>;
  createUser: (request: InvitationResponseProps) => Promise<ApiResponse<number>>;
};

export const refreshUserToken = async ({
  refreshToken,
}: RefreshTokenProps): Promise<LogInResponseType> => {
  const body = toFormData({
    Grant_type: 'refresh_token',
    Refresh_token: refreshToken,
    Username: '',
    Password: '',
  });

  const params = {
    method: 'POST',
    body,
  };

  return callFetchApi(`connect/token`, null, params).then((response) => response.json());
};

export const useSessionApi = (): UseSessionApiReturnType => {
  const { fetchApi } = useFetchApi();

  const logIn = ({ username, password }: LogInProps): Promise<LogInResponseType> => {
    const body = toFormData({
      Grant_type: 'password',
      Username: username,
      Password: password,
    });

    const params = {
      method: 'POST',
      body,
    };

    return fetchApi(`connect/token/partner`, params).then((response) => response.json());
  };
  const findInvitation = async ({
    email,
    token,
  }: InvitationProps): Promise<InvitationResponseProps> => {
    const payload = {
      username: email,
      token,
    };
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`api/authorize/get-invitation`, params);
    return response.json();
  };

  const createUser = async (payload: InvitationResponseProps): Promise<ApiResponse<number>> => {
    const body = JSON.stringify(payload);
    const params = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body,
    };

    const response = await fetchApi(`api/authorize/create-user`, params);
    return response.json();
  };
  return {
    logIn,
    refreshUserToken,
    findInvitation,
    createUser,
  };
};
