import { callFetchApi } from 'shared/functions/fetchApi';
import { ApiBaseProps } from 'shared/types/api';
import { UserAccountApiResponse } from 'shared/types/userProfile';

type UseUserAccountApiReturnType = {
  fetchUserAccountInfo: (apiBaseProps: ApiBaseProps) => Promise<UserAccountApiResponse>;
};

export const useUserAccountApi = (): UseUserAccountApiReturnType => {
  const fetchUserAccountInfo = ({ token }: ApiBaseProps): Promise<UserAccountApiResponse> => {
    return callFetchApi(`api/account/get-current`, token).then((response) => response.json());
  };

  return {
    fetchUserAccountInfo,
  };
};
