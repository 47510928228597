import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilApplications, cilInstitution, cilListRich, cilMoney, cilPhone } from '@coreui/icons';
import { CNavItem } from '@coreui/react';
import { routeContacts, routeDashboard, routeProfile, routeRfqs } from './routes';

const _nav = [
  {
    component: CNavItem,
    name: 'Overview',
    to: routeDashboard,
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'Orders',
    to: '/orders',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'RFQ',
    to: routeRfqs,
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    badge: {},
  },
  {
    component: CNavItem,
    name: 'Manage',
    to: undefined,
    icon: undefined,
    badge: {},
    items: [
      {
        component: CNavItem,
        name: 'Profile',
        to: routeProfile,
        icon: <CIcon icon={cilInstitution} customClassName="nav-icon" />,
        badge: {},
      },
      {
        component: CNavItem,
        name: 'Contacts',
        to: routeContacts,
        icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
        badge: {},
      },
      /* {
        component: CNavItem,
        name: 'Fleet',
        to: '/dashboard3',
        icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
        badge: {},
      },*/
    ],
  },
  {
    component: CNavItem,
    name: 'Payment',
    to: undefined,
    icon: undefined,
    badge: {},
    items: [
      {
        component: CNavItem,
        name: 'Invoices',
        to: '/invoices',
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
        badge: {},
      },
    ],
  },
];

export default _nav;
