import React, { Suspense, FC, ReactElement } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { theme } from 'theme';

import { SystemAlertProvider } from 'shared/hooks/useSystemAlert';
import { FullPageLoader } from 'components';
import AppContents from './AppContents';
import { UserContextProvider } from './UserContextProvider';

const App: FC = (): ReactElement => {
  return (
    <UserContextProvider>
      <ThemeProvider theme={theme}>
        <SystemAlertProvider>
          <CssBaseline />
          <Suspense fallback={<FullPageLoader />}>
            <AppContents />
          </Suspense>
        </SystemAlertProvider>
      </ThemeProvider>
    </UserContextProvider>
  );
};

export default App;
