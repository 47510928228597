import React, { FC, ReactElement } from 'react';
import createPersistedState from 'use-persisted-state';

import { dummyUserContextData, UserContext, UserContextData } from 'shared/hooks/useUser';
import { LogInState } from 'shared/types/session';
import { UserProfileState } from 'shared/types/userProfile';
import { UserOnboardingState } from 'shared/types/userOnboarding';
import { UserUnreadCommentsState } from 'shared/types/comments';

const useLoginState = createPersistedState<LogInState>('laasone-partner-login');
const useUserProfileState = createPersistedState<UserProfileState>('laasone-partner-user');
const useUserOnboardingState = createPersistedState<UserOnboardingState>(
  'laasone-partner-onboarding',
);
const useUnreadComments = createPersistedState<UserUnreadCommentsState>(
  'laasone-partner-unread-comments',
);

type UserContextProviderType = {
  children: React.ReactNode;
};
export const UserContextProvider: FC<UserContextProviderType> = ({ children }): ReactElement => {
  const [authDataState, setAuthDataState] = useLoginState(null);
  const [userProfileState, setUserProfileState] = useUserProfileState(null);
  const [userOnboardingState, setUserOnboardingState] = useUserOnboardingState({
    shipments: true,
    priceRequests: true,
  });
  const [userUnreadCommentsState, setUserUnreadCommentsState] = useUnreadComments(undefined);

  const initialUserContextData: UserContextData = {
    ...dummyUserContextData,
    authData: authDataState,
    setAuthData: setAuthDataState,
    userProfile: userProfileState,
    setUserProfile: setUserProfileState,
    userOnboarding: userOnboardingState,
    setUserOnboarding: setUserOnboardingState,
    userUnreadComments: userUnreadCommentsState,
    setUserUnreadComments: setUserUnreadCommentsState,
  };

  return <UserContext.Provider value={initialUserContextData}>{children}</UserContext.Provider>;
};
