import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const black = '#000000';
const white = '#ffffffff';
const veryDarkViolet = '#26084d';
const veryDarkDesaturatedViolet = '#513971';
const veryLightGray = '#f4f3f6';
const veryLightGrayAlt = '#ebebeb';
const veryLightGrayAlt2 = '#f5f5f5';
const veryLightGrayAlt3 = '#c4c4c4';
const gray = '#858585';
const grayAlt = '#adadad';
const lightGrayishViolet = '#e9e6ed';
const lightGrayishVioletAlt = '#d4cedb';
const lightGrayishVioletAlt2 = '#e1d4f1';
const lightGrayishVioletAlt3 = '#f0eaf8';
const lightGrayishVioletAlt4 = '#f7f4fb';
const veryDarkGray = '#333333';
const veryDarkGrayAlt = '#3d3d3d';
const veryDarkGrayAlt2 = '#5c5c5c';
const darkCyan = '#008094';
const lightCyan = '#e5f2f4';
const lightGrayishCyan = '#cce6ea';
const mostlyDesaturatedDarkViolet = '#7d6b94';
const grayishViolet = '#a89cb8';
const strongViolet = '#6829b8';
const verySoftViolet = '#c3a9e3';
const veryLightYellow = '#ffe178';
const vividYellow = '#fac91a';
const brightYellow = '#fbd448';
const brighterYellow = '#fde9a3';
const menuBgYellow = '#FCDF76';
const lightGray = '#d6d6d6';
const brightRed = '#f44336';
const slightlyDesaturatedCyan = '#99ccd4';
const slightlyDesaturatedCyanAlt = '#66b3bf';
const alertInfo = '#00388b';
const alertBackSuccess = '#007862';
const alertError = '#b80037';

const breakpoints = {
  desktop: 1366,
};

export const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      contrastText: white,
      main: veryDarkViolet,
    },
    secondary: {
      contrastText: veryDarkViolet,
      main: vividYellow,
    },
    error: {
      main: alertError,
      secondary: alertError,
    },
    custom: {
      black,
      white,
      veryDarkDesaturatedViolet,
      veryLightGray,
      veryLightGrayAlt,
      veryLightGrayAlt2,
      grayishViolet,
      lightGrayishViolet,
      lightGrayishVioletAlt,
      lightGrayishVioletAlt2,
      lightGrayishVioletAlt3,
      lightGrayishVioletAlt4,
      darkCyan,
      lightCyan,
      slightlyDesaturatedCyan,
      slightlyDesaturatedCyanAlt,
      lightGrayishCyan,
      mostlyDesaturatedDarkViolet,
      verySoftViolet,
      veryDarkViolet,
      veryDarkGray,
      veryDarkGrayAlt,
      veryDarkGrayAlt2,
      strongViolet,
      veryLightYellow,
      vividYellow,
      brightYellow,
      gray,
      grayAlt,
      lightGray,
      brightRed,
      alertError,
      alertBackSuccess,
      alertInfo,
      veryLightGrayAlt3,
      brighterYellow,
      menuBgYellow,
    },
    text: {
      primary: veryDarkGray,
      secondary: white,
    },
  },
  shadows: [
    '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    '4px 4px 10px rgba(0, 0, 0, 0.25)',
    '4px 4px 10px 2px rgba(0, 0, 0, 0.15)',
    '4px 4px 10px rgba(0, 0, 0, 0.15)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      ...breakpoints,
      xs: 0,
      sm: 600,
      iPhone5: 670,
      md: 960,
      lg: 1126,
      desktop: 1310,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial'].join(','),
    h1: {
      fontWeight: 900,
      fontSize: '3.125rem',
      lineHeight: '4.25rem',
    },
    h2: {
      fontWeight: 800,
      fontSize: '3.125rem',
      lineHeight: '4.25rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '3rem',
      },
    },
    h3: {
      fontWeight: 900,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
    },
    h4: {
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '1.625rem',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
      fontWeight: 500,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1.07rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // SG-534 - couldn't make it work within several
        // hours before release so took it out for now.
        // html: {
        //   overflowX: 'hidden',
        // },
        // body: {
        //   overflowX: 'hidden',
        // },
        a: {
          color: veryDarkViolet,
          '&:hover': {
            textDecoration: 'none',
          },
        },
        sup: {
          lineHeight: 0,
        },
        ':focus': {
          outline: '-webkit-focus-ring-color auto 5px',
          outlineStyle: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '0.875rem',
        textTransform: 'none',
        padding: defaultTheme.spacing(1.5, 3),
      },
      outlined: {
        padding: defaultTheme.spacing(1.5, 3),
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'unset',
          borderWidth: 1,
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '80px',
      },
    },
    MuiInputLabel: {
      root: {
        marginBottom: defaultTheme.spacing(1),
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: defaultTheme.spacing(1.5, 1.75),
      },
      adornedEnd: {
        paddingRight: defaultTheme.spacing(1.125),
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        width: '2rem',
        height: '2rem',
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        color: veryDarkViolet,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.75rem',
        whiteSpace: 'normal',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: lightGrayishVioletAlt,
        },
      },
      root: {
        '&$selected': {
          fontWeight: 700,
          backgroundColor: lightGrayishViolet,
          '&:hover': {
            backgroundColor: lightGrayishViolet,
          },
        },
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: '0.75rem',
        color: black,
        lineHeight: '1.063rem',
        fontWeight: 700,
        marginBottom: '12px',
        marginTop: '20px',
      },
      gutters: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
    },
    MuiAutocomplete: {
      paper: {
        borderRadius: '8px',
      },
      option: {
        color: veryDarkGray,
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        boxSizing: 'border-box',
        minHeight: '48px',
        fontWeight: 500,
        lineHeight: '1.25rem',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: lightGrayishVioletAlt,
        },
        '&[data-focus="true"]': {
          backgroundColor: 'transparent',
        },
      },
      listbox: {
        padding: '16px 0px 32px 0px',
        '& li .MuiListSubheader-root': {
          marginBottom: '8px',
        },
        '& li:nth-of-type(1) .MuiListSubheader-root': {
          marginTop: '0px',
        },
      },
      groupUl: {
        '& .MuiAutocomplete-option': {
          paddingLeft: '12px',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 100,
        backgroundColor: darkCyan,
        color: white,
        height: 24,
        fontSize: '0.75rem',
      },
      label: {
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: lightGray,
        color: veryDarkGray,
        fontSize: '0.75rem',
        padding: defaultTheme.spacing(1.5),
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',
        width: 157,
      },
      arrow: {
        color: lightGray,
      },
    },
    MuiCollapse: {
      hidden: {
        display: 'none',
      },
    },
  },
});
