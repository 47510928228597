import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';

import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  colorInvert: {
    color: theme.palette.custom.white,
    borderColor: theme.palette.custom.white,
    '&:hover': {
      backgroundColor: theme.palette.custom.veryDarkDesaturatedViolet,
    },
  },
  primary: {
    '&:hover': {
      backgroundColor: theme.palette.custom.veryDarkDesaturatedViolet,
    },
  },
  secondary: {
    '&:hover': {
      backgroundColor: theme.palette.custom.veryLightYellow,
    },
  },
  small: {
    fontSize: '0.75rem',
    padding: theme.spacing(1.875, 6),
  },
}));

type ButtonProps = {
  children: React.ReactNode;
  color?: 'default' | 'primary' | 'secondary' | 'inherit' | 'invert';
  size?: 'small' | 'normal';
  onClick?: () => void;
} & Omit<MaterialButtonProps, 'color'>;

const Button: FC<ButtonProps> = ({
  children,
  size = 'normal',
  color = 'default',
  onClick = () => null,
  className,
  ...props
}): ReactElement => {
  const classes = useStyles();

  const colorInvert = color === 'invert';
  const colorPrimary = color === 'primary';
  const colorSecondary = color === 'secondary';

  return (
    <MaterialButton
      onClick={onClick}
      color={color === 'invert' ? 'default' : color}
      className={clsx(
        colorInvert && classes.colorInvert,
        colorPrimary && classes.primary,
        colorSecondary && classes.secondary,
        size === 'small' && classes.small,
        className,
      )}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
