import React, { useContext } from 'react';
import { LogInState } from 'shared/types/session';
import { UserProfileState } from 'shared/types/userProfile';
import { UserOnboardingState } from 'shared/types/userOnboarding';
import { UserUnreadCommentsState } from 'shared/types/comments';
import { refreshUserToken } from 'shared/services/session';
import { isTokenPeriodValid } from 'shared/functions/isTokenPeriodValid';

export type UserData = {
  isLoggedIn: boolean;
  token: string | null | undefined;
  refreshToken: string | null | undefined;
  userName: string | null | undefined;
  profile: UserProfileState | null | undefined;
  expirationDate: number | null | undefined;
  userOnboarding: UserOnboardingState;
  userUnreadComments: UserUnreadCommentsState | null;
  logIn: (authData: LogInState, userProfile: UserProfileState) => void;
  refreshTokenState: (authData: LogInState) => void;
  logOut: () => void;
  toggleOnboarding: (type: 'shipments' | 'price-requests', active: boolean) => void;
  setNewComments: (comments: UserUnreadCommentsState | undefined) => void;
  setUserProfile: (userProfilePartial: Partial<UserProfileState>) => void;
  getValidToken: () => Promise<string | null | undefined>;
};

export type UserContextData = {
  authData: LogInState | null;
  setAuthData: React.Dispatch<React.SetStateAction<LogInState>>;
  userProfile: UserProfileState | null;
  setUserProfile: React.Dispatch<React.SetStateAction<UserProfileState>>;
  userOnboarding: UserOnboardingState;
  setUserOnboarding: React.Dispatch<React.SetStateAction<UserOnboardingState>>;
  userUnreadComments: UserUnreadCommentsState | null;
  setUserUnreadComments: React.Dispatch<React.SetStateAction<UserUnreadCommentsState | undefined>>;
  logIn: (authData: LogInState, userProfile: UserProfileState) => void;
  logOut: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const dummyFunction = () => {};

export const dummyUserContextData: UserContextData = {
  authData: null,
  setAuthData: dummyFunction,
  userProfile: null,
  setUserProfile: dummyFunction,
  userOnboarding: { shipments: true, priceRequests: true },
  setUserOnboarding: dummyFunction,
  userUnreadComments: null,
  setUserUnreadComments: dummyFunction,
  logIn: dummyFunction,
  logOut: dummyFunction,
};

export const UserContext = React.createContext<UserContextData>(dummyUserContextData);

export const useUser = (): UserData => {
  const userContext = useContext(UserContext);

  const { expirationDate, token, refreshToken } = userContext.authData ?? {};
  const { username } = userContext.userProfile ?? {};

  const logOut = () => {
    userContext.setAuthData(null);
    userContext.setUserProfile(null);
  };

  const logIn = (authData: LogInState, userProfile: UserProfileState) => {
    userContext.setAuthData(authData);
    userContext.setUserProfile(userProfile);
  };

  const refreshTokenState = (authData: LogInState) => {
    userContext.setAuthData(authData);
  };

  const toggleOnboarding = (type: string, active: boolean) => {
    userContext.setUserOnboarding({ [type]: active });
  };

  const setNewComments = (comments: UserUnreadCommentsState | undefined) => {
    userContext.setUserUnreadComments(comments);
  };

  const setUserProfile = (userProfilePartial: Partial<UserProfileState>) => {
    userContext.setUserProfile({ ...userContext.userProfile, ...userProfilePartial });
  };

  const getValidToken = async (): Promise<string | null | undefined> => {
    const isTokenValid = isTokenPeriodValid(expirationDate ?? null);
    if (!isTokenValid && refreshToken) {
      try {
        return await refreshUserToken({
          refreshToken,
        }).then(({ access_token, expires_in }) => {
          refreshTokenState({
            username: username ?? null,
            token: access_token,
            expirationDate: Date.now() + expires_in * 1000,
            refreshToken,
          });
          return access_token;
        });
      } catch {
        return null;
      }
    }
    return token;
  };

  return {
    isLoggedIn: !!token,
    token,
    userName: username,
    profile: userContext.userProfile,
    expirationDate,
    refreshToken,
    userOnboarding: userContext.userOnboarding,
    userUnreadComments: userContext.userUnreadComments,
    toggleOnboarding,
    logIn,
    logOut,
    refreshTokenState,
    setNewComments,
    setUserProfile,
    getValidToken,
  };
};
