const translations = {
    BACK: 'Takaisin',
    ACCOUNT: 'Tili',
    WELCOME: 'Tervetuloa',
    OVERVIEW: 'Yhteenveto',
    ORDERS: 'Tilaukset',
    RFQ: 'Pyyntö',
    PROFILE: 'Profiili',
    CONTACTS: 'Yhteystiedot',
    INVOICES: 'Laskut',
    MANAGE: 'Hallinta',
    PAYMENT: 'Maksu',
    COMING_SHIPMENTS: 'Tulevat lähetykset',
    IN_PROGRESS_SHIPMENTS: 'Kesken olevat lähetykset',
    Delayed: 'Viivästynyt',
    WAITING_RFQ: 'Odottaa pyyntöä',
    YOU_ARE_LOGGED_OUT: "Olet kirjautunut ulos",
    OR: 'TAI',
    CANCEL: 'Peruuta',
    CONTINUE: 'Jatka',
    LOGIN: 'Kirjaudu sisään',
    LOG_OUT: 'Kirjaudu ulos',
    TOTAL: 'Yhteensä',
    LOAD_DETAILS: 'Lastaustiedot',
    FAILED_TO_LOG_IN: 'Kirjautuminen epäonnistui',
    FAILED_TO_LOG_IN_BLOCKED: 'Tili on estetty',
    CLOSE_ALERT: 'Sulje',
    ALL_SHIPMENTS_DONE: "Tällä hetkellä lähetyksiä ei ole.",
    STATUS_CHANGED:'Tila päivitetty',
    DASHBOARD: {
        TITLE_1: 'Tämän päivän / myöhästyneet noudot ja toimitukset',
        TITLE_2: 'Tulevat lähetykset'
    },
    DATATABLE: {
        NO_RESULT: 'Tietueita ei löytynyt',
        SORT: 'Lajittele',
        next: 'Seuraava sivu',
        previous: 'Edellinen sivu',
        rowsPerPage: 'Rivejä per sivu',
        displayRows: 'kohteesta'
    },
    LOGIN_DATA: {
        TITLE: 'Kirjautumistiedot',
        LAST_LOGIN: 'Viimeinen kirjautuminen'
    },
    LOGIN_PAGE: {
        TITLE: "Kumppaniportaali",
        TEXT: "Kirjaudu tilillesi",
        BUTTON: "Kirjaudu sisään",
        USERNAME: "Käyttäjänimi",
        PASSWORD: "Salasana",
        FORGOT_PASSWORD: "Unohditko salasanan?",
        SIGN_UP: "Järjestelmämme pitää sinut yhteydessä 24/7 varmistaen saumattoman yhteistyön ja auttaa meitä palvelemaan asiakkaitamme tehokkaasti.",
        SIGN_UPTEXT: "Raskas kuljetus ei ole koskaan ollut näin helppoa",
        AS_SHIPPER: "Rekisteröidy lähettäjänä!",
        AS_CARRIER: "Rekisteröidy kuljettajana!"
    },
    DRIVER_VIEW: {
        CHOOSE_LANGUAGE: 'Valitse kieli',
        CMR_ADDED: 'CMR LISÄTTY',
        CMR_UPLOADED: 'CMR LADATTU',
        COLLECTED: 'LASTATTU',
        DELIVERED: 'TOIMITETTU',
        DELIVERY_REFERENCE: 'Viite',
        DROP_OFF: 'Toimitus',
        FROM: 'mistä',
        INSTRUCTIONS_LOADING: 'Ohjeet',
        INSTRUCTIONS_DELIVERY: 'Ohjeet',
        LOADING_REFERENCE: 'Viite',
        ORDER: 'Tilaus',
        PHOTO_OF_SIGNED_CMR: 'Kuva allekirjoitetusta CMR-stä',
        PICKUP: 'Nouto',
        SEND_CMR: 'LÄHETÄ CMR',
        TIME: 'Kello',
        TIME_LC: 'kello',
        TO: 'mihin',
    },
    PICKUP_AND_DELIVERY_DATES: 'Nouto- ja toimitustiedot',
    PICKUP_FROM: 'Nouto',
    DELIVERY_TO: 'Toimitus',
    EARLIEST_PICKUP: 'Varhaisin nouto',
    LATEST_PICKUP: 'Viimeisin nouto',
    EARLIEST_DELIVERY: 'Varhaisin toimitus',
    LATEST_DELIVERY: 'Viimeisin toimitus',
    PricesOffered: 'Olet tehnyt tarjouksen',
    WaitingPrices: 'Lähetä tarjouksesi',
    Rejected: 'Hylätty',
    Expired: 'Päättynyt',
    Approved: 'Hyväksytty',
    SPOT_REQUEST: {
        TITLE: 'Spot-hintapyyntö',
        TITLE_DESC: 'Vastaa pyyntöömme ja tarjoa hintasi',
        DETAILS: 'Yksityiskohdat',
        CARRIER: 'Kuljetusliike',
        OPENED_UNTIL: 'Avoinna saakka',
        STATUS: 'Status',
        SHIPMENT: 'Lähetys',
        CONTACT_PERSON: 'Yhteystiedot',
        CONTACT_PERSON_EMAIL: 'Sähköposti',
        COMMENT: 'Huomautukset',
        YOUR_PRICES: 'Tarjoa hinta',
        PRICE: 'Hinta',
        DELIVERY_TIME: 'Kuljetusaika',
        PRICE_COMMENT: 'Huomautus',
        PRICE_VALID_UNTIL: 'Hinta voimassa asti',
        ADD_PRICE: 'Lisää hinta',
        MAKE_OFFER: 'Lähetä tarjous',
        REJECT: 'Hylkää',
        ASTRICS_REQUIRED: '* ovat pakollisia kenttiä',
        SUBMIT_SUCCESS: 'Tarjotut hinnat. Kiitos!',
        ORDERER: "Tilaaja",
        LAASONE_COMMENT: "LaasOne kommentti",
        NUMBER: "Numero",
        DATE: "Päivämäärä",
        ASKED_FROM: "Pyyntö vastaanottaja",
        PICKUP: "Nouto-osoite",
        DELIVERY: "Toimitusosoite",
        OFFERED_PRICE: "Tarjottu hinta",
        WAITING_PRICES_UNTIL: "Aktiivinen asti"
    },
    ITEMS_IN_THE_SHIPMENT: 'Lähetyksen tuotteet',
    SHIPMENT: {
        PICKUP_DELIVERY_CONTACTS: 'Nouto- ja toimituskontaktitiedot',
        PICKUP_DELIVERY_NOTES: 'Huomiot',
        TRANSPORT_DELIVERY_WITH_TAIL_LIFT_TRUCK:'Toimitus takalavatrukilla',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Nouto takalavatrukilla',
        TRANSPORT_DANGEROUS_GOODS: 'Sisältää vaarallisia aineita',
        ITEM: {
            SELECT_TYPE: 'Valitse tyyppi',
            DETAILS: {
                PC: 'kpl',
                TYPE: 'Tyyppi',
                LENGTH: 'Pituus',
                LENGTH_TOOLTIP: 'Pituus on kiinteä valitulle tuotetyypille',
                WIDTH: 'Leveys',
                WIDTH_TOOLTIP: 'Leveys on kiinteä valitulle tuotetyypille',
                HEIGHT: 'Korkeus',
                VOLUME: 'Volyymi',
                WEIGHT_PC: 'Paino/kpl',
                TOTAL_WEIGHT: 'Kokonaispaino',
                LDM: 'Lavametriä',
                LDM_TOOLTIP: 'Lavametrit (perävaunun pituuden juoksumetrit täydellä leveydellä ja korkeudella). Tämä lasketaan automaattisesti pituuden, leveyden ja määrän perusteella, mutta voit kirjoittaa eri arvon tarvittaessa.',
                REFERENCE_NUMBER: 'Viitenumero',
                PRODUCT_DESCRIPTION: 'Tuotteen kuvaus',
                UN_NO: 'UN no',
                UN_NO_TOOLTIP: 'Numero, joka tunnistaa vaaralliset aineet, vaaralliset aineet ja esineet',
                UN: 'UN',
                UN_CODE: 'UN-kood',
                PG: 'PG',
                PG_TOOLTIP: 'Vaaralliset aineet jaetaan 3 pakkausryhmään niiden aiheuttaman vaaran asteen mukaan',
                CLASS: 'Luokka',
                CLASS_TOOLTIP: 'Vaarallisten aineiden luokitusnumero',
                DANGEROUS_GOODS: 'Vaaralliset tavarat',
                COLD_FROZEN: 'Kylmä/jäädytetty',
                STACKABLE: 'Pinottava',
                DELICATE: 'Herkkä',
                MIN_TEMP: 'Min. lämpötila',
                MIN_TEMP_TOOLTIP: 'Tavaroiden vähimmäislämpötila, jota ne tarvitsevat kuljetukseen',
                MAX_TEMP: 'Max lämpötila',
                MAX_TEMP_TOOLTIP: 'Tavaroiden maksimilämpötila, jota ne tarvitsevat kuljetukseen',
                PROPER_SHIPPING_NAME: 'Oikea tuotteen nimi',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Nimi, joka kuvaa vaarallisten aineiden vaaraominaisuuksia ja koostumusta',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Musta jauhe, puristettu tai ruuti',
            },
            TYPE: {
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                TRUCK: 'Kuorma-auto',
                OTHER: 'Muu',
                CONTAINER: 'Kontti',
                MACHINE:'Kone'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR-lava',
                FIN_PALLET: 'FIN-lava',
                PALLET: 'Lava',
                PACKAGE: 'Paketti',
                BOX: 'Laatikko',
                FULL_TRUCK_LOAD: 'Täysi kuorma',
                LONG_PALLET: 'Pitkä lava',
                HALF_PALLET: 'Puolilava',
                LDM: 'Lavametriä',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Muu',
                MACHINE:'Kone'
            },
            CLASS: {
                EXPLOSIVES: 'Räjähteet',
                GASES: 'Kaasut',
                FLAMMABLE_LIQUIDS: 'Syttyvät nesteet',
                FLAMMABLE_SOLIDS: 'Syttyvät kiinteät aineet',
                OXIDIZERS: 'Syttyvät kiinteät aineet',
                TOXIC_SUBSTANCES: 'Toksiset aineet',
                RADIOACTIVE: 'Radioaktiivinen',
                CORROSIVES: 'Syövyttävät aineet',
                MISCELLANOUS: 'Sekalaista',
            },
            PG: {
                PG1: 'Pakkausryhmä I: korkea vaara',
                PG2: 'Pakkausryhmä II: keskivaara',
                PG3: 'Pakkausryhmä III: vähäinen vaara',
            },
        },
    },
    SWITCH: {
        YES: 'KYLLÄ',
        NO: 'EI',
    },
    DATEPICKER_PLACEHOLDER: "dd.mm.yyyy",
    'This username already exists': 'This username already exists',
    'This company already exists': 'This company already exists',
    ORDER: {
        NUMBER: "Numero",
        CONSIGNOR: "Lähettäjä",
        PICKUP: "Nouto-osoite",
        PICKUP_DATE: "Noutopäivä",
        CONSIGNEE: "Vastaanottaja",
        DELIVERY: "Toimitusosoite",
        DELIVERY_DATE: "Toimituspäivä",
        ORDERER: "Ostaja",
        STATUS: "Tila",
        STATUSES: {
            NEW: 'Uusi',
            IN_PROGRESS: 'Työn alla',
            IN_TRANSIT: 'Noudettu',
            DELIVERED: 'Toimitettu',
            CANCELED: 'Peruutettu',
            PICKED_UP: 'Noudettu'
        },
        CHANGE_STATUS: 'Vaihda tila',
        CHANGE_STATUS_TEXT: 'Oletko varma, että haluat vaihtaa tilaa?'
    },
    INVOICE: {
        TITLE: 'Luettelossa näytetään vain itse laskutetut laskut',
        NUMBER: 'Numero',
        DATE: 'Päivämäärä',
        Total_SUM: 'Kokonaissumma'
    },
    PROFILE_PAGE: {
        INFO: 'Jos huomaat profiilissasi virheitä, ilmoitathan meille osoitteeseen info@laasone.com.',
        TITLE_ABOUT: 'Yrityksen tiedot',
        TITLE_ADDRESS: 'Osoitetiedot',
        TITLE_SELF_BILLING: 'Itse laskutuksen tiedot',
        COMPANY_NAME: 'Yrityksen nimi',
        REGISTER_NUMBER: 'Rekisterinumero',
        VAT_NUMBER: 'ALV-numero',
        EMAIL: 'Sähköposti',
        DEFAULT_LANGUAGE: 'Oletuskieli',
        ADDRESS: 'Osoite',
        POSTAL_CODE: 'Postinumero',
        CITY: 'Kaupunki',
        STATE: 'Maakunta',
        COUNTRY: 'Maa',
        SELF_BILLING_ACTIVATED: 'Aktiivinen',
        PAYMENT_TERMS_DAYS: 'Ostojen maksuehdot päivinä',
        BILLING_FREQUENCY: 'Laskutuksen tiheys',
        INVOICE_EMAIL: 'Lasku lähetetään sähköpostiin'
    },
    NOT_FOUND_HEADING: "Voi ei!",
    NOT_FOUND_DESC: "Etsimääsi sivua ei ole olemassa tai ",
    NOT_FOUND_DESC_2: "sinulla ei ole oikeuksia sen näkemiseen.",
    NOT_FOUND_ERROR_CODE: "Virhekoodi: 404",
    GO_TO_HOMEPAGE: "Siirry aloitussivulle",
    LIST: 'Luettelo',
    ALERT:{
        ORDER_LIST: 'Tilauksesi viimeisten 30 päivän ajalta',
        RFQ_LIST: 'Tarjouspyyntösi viimeisten 30 päivän ajalta'
    }
}
export default translations;