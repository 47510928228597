import React, { ReactElement, FC } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Box, { BoxProps } from '@material-ui/core/Box';

import { theme } from 'theme';
import { QuestionMarkIcon, Typography } from 'components';
import { useCommonStyles } from 'shared/styles/common';

const useStyles = makeStyles({
  cellLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.custom.veryDarkGrayAlt2,
    display: 'flex',
    alignItems: 'center',
  },
  cellText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
});

type DataCellProps = {
  label: string;
  data?: string | number | ReactElement | null;
  unit?: string;
  tooltip?: string;
} & BoxProps;

const DataCell: FC<DataCellProps> = ({ label, data, unit, tooltip, ...boxProps }): ReactElement => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  return (
    <Box className={classes.cellWrapper} {...boxProps}>
      <Typography component="p" variant="caption" className={classes.cellLabel}>
        {label} {tooltip ? <QuestionMarkIcon className={classes.tooltipIcon} /> : null}
      </Typography>
      <Typography component="p" variant="caption" className={classes.cellText}>
        <span className={commonClasses.semibold}>{data || '–'}</span> {unit}
      </Typography>
    </Box>
  );
};

export default DataCell;
