import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';

import { Button, Typography } from 'components';

import { theme } from 'theme';
import { routeLogin } from '../../routes';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    zIndex: 1,
  },
  img: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-50%, -60%)',
    },
    width: '100%',
    zIndex: 0,
    overflow: 'hidden',
  },
  heading: {
    color: theme.palette.custom.darkCyan,
    marginTop: theme.spacing(95),
    [theme.breakpoints.up(1680)]: {
      marginTop: theme.spacing(105),
    },
    [theme.breakpoints.up(1920)]: {
      marginTop: theme.spacing(110),
    },
    [theme.breakpoints.up(2560)]: {
      marginTop: '65%',
    },
    [theme.breakpoints.up(3840)]: {
      marginTop: '90%',
    },
    marginBottom: theme.spacing(3),
    fontSize: '2rem',
    lineHeight: '40px',
  },
  subtitle: {
    color: theme.palette.custom.veryDarkGray,
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'left',
    },
  },
  errorCode: {
    color: theme.palette.custom.veryDarkGray,
    fontSize: '1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  button: {
    backgroundColor: theme.palette.custom.veryDarkViolet,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    borderRadius: 8,
  },
}));

type NotFoundProps = {
  hideHomeButton?: boolean;
};
const NotFound: FC<NotFoundProps> = ({ hideHomeButton }): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles({});

  const goToHomepage = () => {
    history.push(routeLogin);
  };

  return (
    <>
      <img className={classes.img} alt="Not found" src="/images/not-found.svg" />
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h1" className={classes.heading}>
          {t('NOT_FOUND_HEADING')}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          className={classes.subtitle}
          color="textSecondary"
        >
          {t('NOT_FOUND_DESC')}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          className={classes.subtitle}
          color="textSecondary"
        >
          {t('NOT_FOUND_DESC_2')}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          className={classes.errorCode}
          color="textSecondary"
        >
          {t('NOT_FOUND_ERROR_CODE')}
        </Typography>
        {!hideHomeButton && (
          <Button
            className={classes.button}
            variant="outlined"
            color="invert"
            onClick={goToHomepage}
          >
            {t('GO_TO_HOMEPAGE')}
          </Button>
        )}
      </Container>
    </>
  );
};

export default NotFound;
