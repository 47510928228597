import React from 'react';
import {
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import { useUser } from 'shared/hooks/useUser';
import { routeLogin } from 'routes';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import TextUtilities from 'helpers/TextUtilities';
import { routePaths } from '../../shared/constants/api.constants';
import { useTranslation } from 'react-i18next';

const AppHeaderDropdown = () => {
  const user = useUser();
  const history = useHistory();
  const { t } = useTranslation();
  const logOut = () => {
    user.logOut();
    history.push(routeLogin);
  };
  const nameLetters = TextUtilities.nameLetters(user?.profile?.name);
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0 user-letters-lg" caret={false}>
        {user?.profile?.name && (
          <div
            className={clsx('rounded-circle user-letters', nameLetters && `user-${nameLetters}`)}
          >
            {nameLetters}
          </div>
        )}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">{t('ACCOUNT')}</CDropdownHeader>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href={routePaths.profile} className="hidden">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#" className="hidden">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem className="pointer mt-0" onClick={logOut}>
          <CIcon icon={cilLockLocked} className="me-2" />
          {t('LOG_OUT')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
