import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { theme } from 'theme';
import { TextFieldFormik, Button, Loader, Labeled } from 'components';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { formStates } from 'shared/constants/formStates';
import { languages } from 'shared/constants/languages';
import { useSessionApi } from 'shared/services/session';
import { useUserAccountApi } from 'shared/services/user';
import { getLanguageByCode } from 'shared/functions/getLanguageByCode';
import qs from 'qs';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { routeDashboard } from 'routes';
import { useUser } from 'shared/hooks/useUser';
import SystemAlert from '../components/CustomMaterial/Alert/Alert';
import ArrowRight from '../components/Icons/Arrows/ArrowRight';
import { useCommonStyles } from '../shared/styles/common';
import { NotFound } from '../scenes';
import { ApiValidationError } from '../shared/types/api';
import { generateKey } from '../shared/functions/generateKey';

const useStyles = makeStyles(() => ({
  wrapper: {},
  topContainer: {
    paddingTop: theme.spacing(10),
    position: 'absolute',
    height: '100%',
    left: 0,
    right: 0,
  },
  container: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
    },
    position: 'relative',
    height: '100%',
  },
  accent1: {
    position: 'absolute',
    top: 53,
    left: -252,
  },
  accent2: {
    position: 'absolute',
    top: 445,
    left: 691,
  },
  accent3: {
    position: 'absolute',
    top: -44,
    left: 36,
  },
  accent4: {
    position: 'absolute',
    bottom: -41,
    right: 26,
  },
  heroTextWrapper: {
    paddingTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0),
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  loginImage: {
    position: 'absolute',
    top: 0,
    maxWidth: '100%',
    objectFit: 'cover',
  },
  logo: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(16, 8, 19),
    marginLeft: theme.spacing(2.5),
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(6),
    textAlign: 'center',
  },
  label: {
    marginTop: theme.spacing(4),
  },
  passwordInput: {
    marginBottom: theme.spacing(6),
  },
  languageSwitcher: {
    right: 40,
  },
  hint: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: '10px',
    fontStyle: 'italic',
  },
}));

type FormValues = {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  repassword: string;
};

const initialValues: FormValues = {
  username: '',
  firstName: '',
  lastName: '',
  password: '',
  repassword: '',
};

const InvitationApp: FC = (): ReactElement => {
  const user = useUser();
  const commonClasses = useCommonStyles();
  const { logIn, findInvitation, createUser } = useSessionApi();
  const { fetchUserAccountInfo } = useUserAccountApi();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { email, token } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    plainObjects: true,
  });
  const [formState, setFormState] = useState(formStates.PRISTINE);
  const [isPending, setIsPending] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [apiErrors, setApiErrors] = useState<ApiValidationError[]>([]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(2, t('VALIDATION.INVALID_VALUE')).required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().min(8, t('VALIDATION.INVALID_USERNAME')).required(),
    repassword: Yup.string().min(8, t('VALIDATION.INVALID_USERNAME')).required(),
  });

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const handleSubmit = async (
    payload: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setApiErrors([]);
    setFormState(formStates.PRISTINE);
    setIsPending(true);
    const urlToken = token ? token.toString() : '';
    try {
      const result = await createUser({
        username: payload.username,
        firstName: payload.firstName,
        lastName: payload.lastName,
        password: payload.password,
        repassword: payload.repassword,
        token: urlToken,
      });
      if (result.validation?.errors?.length > 0) {
        setApiErrors(result.validation?.errors);
        throw new Error('USER_NOT_GENERATED');
      } else {
        setIsPending(true);
      }
      const {
        isBadRequest,
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        errors,
      } = await logIn({
        username: payload.username,
        password: payload.password,
      });
      if (!isBadRequest && accessToken) {
        const { payload: accountInfo } = await fetchUserAccountInfo({
          token: accessToken,
        });
        user.logIn(
          {
            username: payload.username,
            token: accessToken,
            expirationDate: Date.now() + expiresIn * 1000,
            refreshToken,
          },
          { ...accountInfo },
        );
        // FIXME: history.replace(routeShipments) or history.push(routeShipments)
        window.location.href = routeDashboard;
      } else if (errors && errors.length > 0) {
        setApiErrors([{ propertyName: 'user', errorMessage: errors[0] }]);
        throw new Error(errors[0]);
      } else {
        window.location.href = '/';
        setApiErrors([{ propertyName: 'user', errorMessage: 'LOGIN_FAILED' }]);
        throw new Error('LOG_IN_FAILED');
      }
    } catch (e) {
      setFormState(formStates.ERROR);
      setSubmitting(false);
      setIsPending(false);
    }
  };

  const loadInvitation = async () => {
    if (email && token) {
      setIsPending(true);
      try {
        const invitationData = await findInvitation({
          email: email.toString(),
          token: token.toString(),
        });
        if (invitationData.username) {
          const data: FormValues = {
            ...initialValues,
            username: invitationData.username,
            lastName: invitationData.lastName,
            firstName: invitationData.firstName,
          };
          setFormValues(data);
        } else {
          setNotFound(true);
        }
      } catch {
        setNotFound(true);
        window.location.href = '/';
      }
      setIsPending(false);
    }
  };
  useEffect(() => {
    if (!email || !token) {
      setNotFound(true);
    } else {
      loadInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card-group d-block d-md-flex row shadow-lg">
              <div className="card col-md-7 p-4 mb-0">
                <div className="d-flex justify-content-end pt-3 position-relative">
                  <LanguageSwitcher
                    onLanguageChange={onLanguageChange}
                    selectedLanguage={getLanguageByCode(i18n.language)}
                    languages={languages}
                    className={''}
                  />
                </div>
                <div className={classes.logo}>
                  <img height={180} alt="LaasOne" src="/images/laasone_logo.png" />
                </div>
                {notFound && <NotFound hideHomeButton />}
                {!notFound && (
                  <>
                    <Typography variant="h3" className={classes.title}>
                      {t('INVITATION_BOX_TITLE')}
                    </Typography>
                    <Typography variant="body2" className={classes.description}>
                      {t('VALIDATION.IS_REQUIRED_TEXT')}{' '}
                    </Typography>
                    <Box position="relative">
                      {formState === formStates.ERROR && (
                        <SystemAlert
                          color="error"
                          fullWidth={false}
                          lessPadding
                          onClose={() => setFormState(formStates.PRISTINE)}
                        >
                          {apiErrors.map((item, key) => {
                            return (
                              <Typography variant="inherit" key={generateKey(key, 'error')}>
                                {t(item.errorMessage)}
                              </Typography>
                            );
                          })}
                        </SystemAlert>
                      )}
                      {isPending && <Loader cover />}
                      <Formik
                        validateOnBlur
                        validateOnChange
                        enableReinitialize
                        initialValues={formValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                      >
                        {({ errors, touched, isSubmitting }) => (
                          <Form>
                            <Labeled
                              id="username-label"
                              className={classes.label}
                              error={!!(errors.username && touched.username)}
                              text={t('USERNAME')}
                              required
                            >
                              <TextFieldFormik
                                name="username"
                                variant="variant2"
                                type="text"
                                error={!!(errors.username && touched.username)}
                                fullWidth
                                isReadonly
                              />
                            </Labeled>
                            <Box display="flex">
                              <Labeled
                                id="firstName-label"
                                className={classes.label}
                                error={!!(errors.firstName && touched.firstName)}
                                text={t('USERS.FIRST_NAME')}
                                required
                                width="49%"
                                marginRight={8}
                              >
                                <TextFieldFormik
                                  name="firstName"
                                  variant="variant2"
                                  type="text"
                                  error={!!(errors.firstName && touched.firstName)}
                                  fullWidth
                                />
                              </Labeled>
                              <Labeled
                                id="lastName-label"
                                className={classes.label}
                                error={!!(errors.lastName && touched.lastName)}
                                text={t('USERS.LAST_NAME')}
                                required
                                width="49%"
                              >
                                <TextFieldFormik
                                  name="lastName"
                                  variant="variant2"
                                  type="text"
                                  error={!!(errors.lastName && touched.lastName)}
                                  fullWidth
                                />
                              </Labeled>
                            </Box>
                            <Labeled
                              id="password-label"
                              className={classes.label}
                              error={!!(errors.password && touched.password)}
                              text={t('PASSWORD')}
                              required
                            >
                              <TextFieldFormik
                                name="password"
                                variant="variant2"
                                type="password"
                                error={!!(errors.password && touched.password)}
                                fullWidth
                              />
                              <Box className={classes.hint}>{t('PASSWORD_HINT')}</Box>
                            </Labeled>
                            <Labeled
                              id="repassword-label"
                              className={classes.label}
                              error={!!(errors.repassword && touched.repassword)}
                              text={t('PASSWORD_AGAIN')}
                              required
                            >
                              <TextFieldFormik
                                name="repassword"
                                className={classes.passwordInput}
                                variant="variant2"
                                type="password"
                                error={!!(errors.repassword && touched.repassword)}
                                fullWidth
                              />
                            </Labeled>
                            <Box textAlign="center">
                              <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting || isPending}
                              >
                                {t('CREATE_USER')}
                                <span className={commonClasses.ml3}>
                                  <ArrowRight />
                                </span>
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationApp;
