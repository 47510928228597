import React, { Suspense, FC, ReactElement, useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { generateKey } from 'shared/functions/generateKey';
import { routeDashboard, RouteProps, routes } from 'routes';

import { NotFound } from 'scenes';

import { Loader } from 'components';
import { BaseProps } from 'shared/types';
import { GlobalData } from 'shared/types/globalData';
import AppHeader from '../components/AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import { useClassifiers } from 'shared/services/classifier';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const RouteLoadingFallback = (
  <div>
    <Loader cover noBackground color="secondary" />
  </div>
);

const useStyles = makeStyles(() => ({
  poweredByGoogle: {
    height: 0, // todo hide now, find the right place for this
    width: 144,
  },
}));

const defaultGlobalData: GlobalData = {
  countries: [],
  characterization: [],
  users: [],
  tenants: [],
  companies: [],
  partners: [],
};

export const GlobalDataContext = React.createContext<GlobalData>(defaultGlobalData);

const AuthenticatedApp: FC<BaseProps> = (): ReactElement => {
  const [globalData, setGlobalData] = useState<GlobalData>(defaultGlobalData);
  const { fetchCountries } = useClassifiers();
  const getCountries = async () => {
    try {
      const countries = await fetchCountries({ language: 'en' });
      setGlobalData((current) => ({ ...current, countries }));
    } catch (error) {
      // TODO: Add error handling when messages notification messages are specified
    }
  };
  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeMapper = (
    { path, component, exact, environment, ...rest }: RouteProps,
    index: number,
  ): ReactElement =>
    component && (
      <Route
        key={generateKey(index, (path.length ? path[0] : path).replace('/', '-'))}
        path={path}
        component={component}
        exact={exact}
        {...rest}
      />
    );

  const renderRoutes = (
    <Suspense fallback={RouteLoadingFallback}>
      <Switch>
        <Redirect from="/" to={routeDashboard} exact />
        {routes.map(routeMapper)}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );

  /* const onLanguageChange = (language: string) => {
      i18n.changeLanguage(language);
    }; */
  const classes = useStyles();
  return (
    <>
      <AppSidebar />
      <GlobalDataContext.Provider value={globalData}>
        <div className="wrapper d-flex flex-column min-vh-100">
          <AppHeader />
          <div className="body flex-grow-1 px-3 position-relative">{renderRoutes}</div>
          <AppFooter />
        </div>
        <CardMedia
          id="Logtech HoldingPoweredByGoogleDiv"
          className={classes.poweredByGoogle}
          image="/images/powered_by_google_on_white.png"
        />
      </GlobalDataContext.Provider>
    </>
  );
};

export default AuthenticatedApp;
