import { Flag } from 'components';
export const languages = [
  { code: 'en', label: 'English', icon: <Flag flag="gb" /> },
  { code: 'fi', label: 'Finnish', icon: <Flag flag="fi" /> },
  { code: 'se', label: 'Swedish', icon: <Flag flag="se" /> },
  { code: 'et', label: 'Estonian', icon: <Flag flag="ee" /> },
  // { code: 'ru', label: 'Russian', icon: <Flag flag="ru" /> },
];

export const languagesRfq = [
  { code: 'en', label: 'English', icon: <Flag flag="gb" /> },
  { code: 'fi', label: 'Finnish', icon: <Flag flag="fi" /> },
  { code: 'et', label: 'Estonian', icon: <Flag flag="ee" /> },
];
