import React, { FC, FocusEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { theme } from 'theme';

import { makeStyles } from '@material-ui/styles';
import MaterialTextField, {
  TextFieldProps as MaterialTextFieldProps,
} from '@material-ui/core/TextField';
import { inputDelay } from 'shared/constants/inputDelay';
import { withFormikFastField, withFormikField } from 'shared/hocs/formik';
import { useDebouncedCallback } from 'use-debounce';

export type StyleProps = {
  color?: string;
  textColor?: string;
  backgroundColor?: string;
  autocompleteColor?: string;
  size?: 'small' | 'normal';
  shadow?: string;
  padding?: string;
  width?: number | string;
  minWidth?: number | string;
  unit?: string;
  ellipsis?: boolean;
  multiline?: boolean;
  isReadonly?: boolean;
};

const useStyles = makeStyles({
  root: ({
    color,
    backgroundColor,
    textColor,
    shadow,
    width,
    minWidth,
    unit,
    isReadonly,
  }: StyleProps) => ({
    '& label.Mui-focused': {
      color,
    },
    '& .MuiInput-underline:after': {
      borderColor: color,
    },
    '& .MuiOutlinedInput-root': {
      width,
      minWidth,
      backgroundColor: isReadonly ? backgroundColor : backgroundColor || theme.palette.custom.white,
      color: textColor,
      '& fieldset': {
        borderColor: isReadonly ? 'transparent' : color,
      },
      '&:hover fieldset': {
        borderColor: color,
      },
      '&.Mui-focused fieldset': {
        borderColor: color,
        borderWidth: 1,
        boxShadow: shadow,
      },
      '&:after': unit
        ? {
            color: isReadonly ? textColor : theme.palette.custom.gray,
            content: `"${unit}"`,
            position: 'absolute',
            right: 12,
          }
        : {},
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.spacing(2, 3.5),
    },
    '& .Mui-error.MuiOutlinedInput-root': {
      color: textColor,
      '& fieldset': {
        borderColor: `${theme.palette.custom.alertError} !important`,
      },
    },
  }),
});

const useNativeStyles = makeStyles({
  root: ({ size, multiline }: StyleProps) => ({
    ...(size === 'small' && { fontSize: '0.75rem', lineHeight: '1.06rem' }),
    ...(size === 'small' && !multiline && { height: 32 }),
  }),
  input: ({ padding, autocompleteColor, textColor, unit, ellipsis }: StyleProps) => ({
    textOverflow: ellipsis ? 'ellipsis' : 'initial',
    padding,
    ...(unit ? { paddingRight: 9 } : {}),
    '&:-internal-autofill-selected': {
      backgroundColor: 'unset !important',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${autocompleteColor} inset`,
      '-webkit-text-fill-color': `${textColor} !important`,
    },
  }),
});

export type Variant = 'variant1' | 'variant2' | 'variant3' | 'variant4' | 'variant5' | 'variant6';

export type TextFieldProps = {
  variant?: Variant;
  size?: 'small' | 'normal';
  width?: number | string;
  minWidth?: number | string;
  unit?: string;
  ellipsis?: boolean;
  isReadonly?: boolean;
  numeric?: boolean;
  debounced?: boolean;
  allowedChars?: RegExp;
} & Omit<MaterialTextFieldProps, 'variant' | 'size'>;

const TextField: FC<TextFieldProps> = ({
  variant = 'variant1',
  size = 'normal',
  width = 'auto',
  minWidth = '',
  unit,
  InputProps,
  ellipsis,
  multiline,
  onChange,
  onBlur,
  value,
  isReadonly,
  numeric,
  debounced,
  allowedChars = '',
  ...props
}): ReactElement => {
  const {
    mostlyDesaturatedDarkViolet,
    lightGrayishVioletAlt,
    grayishViolet,
    veryLightGray,
    lightGray,
    white,
  } = theme.palette.custom;

  const defaultValues: Record<Variant, string> = {
    variant1: '',
    variant2: '',
    variant3: '',
    variant4: '',
    variant5: '',
    variant6: '',
  };

  const colors: Record<Variant, string> = {
    variant1: mostlyDesaturatedDarkViolet,
    variant2: lightGrayishVioletAlt,
    variant3: grayishViolet,
    variant4: white,
    variant5: white,
    variant6: lightGray,
  };

  const textColors: Record<Variant, string> = {
    ...defaultValues,
    variant1: theme.palette.text.secondary,
  };

  const backgroundColors: Record<Variant, string> = {
    ...defaultValues,
    variant1: 'transparent',
  };

  const autocompleteColors: Record<Variant, string> = {
    variant1: theme.palette.primary.main,
    variant2: veryLightGray,
    variant3: lightGrayishVioletAlt,
    variant4: veryLightGray,
    variant5: veryLightGray,
    variant6: veryLightGray,
  };

  const shadows: Record<Variant, string> = {
    ...defaultValues,
    variant5: theme.shadows[1],
  };

  const paddings: Record<Variant, string> = {
    ...defaultValues,
    variant5: theme.spacing(2.25, 3, 1.75, 3),
  };

  const color = colors[variant];
  const textColor = textColors[variant] || theme.palette.text.primary;
  const backgroundColor = backgroundColors[variant];
  const autocompleteColor = autocompleteColors[variant];
  const shadow = shadows[variant];
  const padding = paddings[variant];

  const classes = useStyles({
    color,
    textColor,
    backgroundColor,
    shadow,
    width,
    minWidth,
    unit,
    isReadonly,
  });

  const nativeClasses = useNativeStyles({
    autocompleteColor,
    textColor,
    size,
    padding,
    unit,
    ellipsis,
    multiline,
  });

  const inputProps = { readOnly: isReadonly, classes: nativeClasses, ...InputProps };

  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    const valueExists = value !== null && value !== undefined;
    setInnerValue(valueExists ? (value as string) : '');
  }, [value]);

  const handleOnChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandleOnChange = useDebouncedCallback(
    handleOnChangeCallback,
    debounced ? inputDelay : 0,
  );

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const newValue = numeric
      ? event.currentTarget.value.replace(',', '.')
      : event.currentTarget.value;

    if (allowedChars instanceof RegExp) {
      if (event.target.value === '' || allowedChars.test(event.target.value)) {
        setInnerValue(newValue);
        debouncedHandleOnChange.callback(event);
      }
    } else {
      setInnerValue(newValue);
      debouncedHandleOnChange.callback(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      debouncedHandleOnChange.flush();
      if (onBlur) {
        onBlur(event);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onBlur],
  );

  return (
    <MaterialTextField
      classes={classes}
      InputProps={inputProps}
      multiline={multiline}
      {...props}
      value={innerValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};

const TextFieldFormik = withFormikFastField<TextFieldProps>(TextField);
const TextFieldSlowFormik = withFormikField<TextFieldProps>(TextField);

export default TextField;
export { TextFieldFormik, TextFieldSlowFormik };
